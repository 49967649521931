import jsonexport from "jsonexport";
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import { DefaultRoute } from "../router/routes";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

export const isUserLoggedIn = () => localStorage.getItem("userData");
export const getUserData = () => JSON.parse(localStorage.getItem("userData"));

export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return DefaultRoute;
  if (userRole === "client") return "/access-control";
  return "/login";
};

export const convertArrayOfObjectsToCSVCustomers = (array) => {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;
      if (
        key === "id" ||
        key === "firstName" ||
        key === "lastName" ||
        key === "email" ||
        key === "phoneNumber" ||
        key === "comment"
      ) {
        result += item[key];
      }

      if (key === "assignedUsers" && item[key].length > 0) {
        result += `${item[key][0].lastName} ${item[key][0].firstName}`;
      }
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

export const downloadExcel = async (array, name, type) => {
  // Convert the array to a workbook
  const worksheet = XLSX.utils.json_to_sheet(array);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate buffer
  const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Create a Blob
  const blob = new Blob([wbout], { type: "application/octet-stream" });

  // Create an anchor element and download the file
  const link = document.createElement("a");
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = `${name || "export"}-${moment().format(
    "YYYY-MM-DD HH:mm"
  )}.xlsx`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const downloadCSV = async (array, type) => {
  const link = document.createElement("a");
  let csv = await jsonexport(array);

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
};

export const downloadCSVCustomer = (array) => {
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSVCustomers(array);
  if (csv === null) return;

  const filename = "export.csv";

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
};

export const paymentTypeDict = {
  ONLINE: "Online",
  Przelewy24: "Online",
  OFFLINE: "Stationary",
  BANK_TRANSFER: "Bank transfer",
  CREDIT_CARD: "Credit card",
  CARD: "Credit card",
  CASH: "Cash",
  "N/A": "N/A",
};

export const getPaymentColor = (appointment) => {
  if (
    appointment.appointmentStatus === "CANCELED" &&
    appointment.paymentStatus === "PENDING"
  ) {
    return "light-secondary";
  } else {
    return appointment.paymentStatus !== "PENDING"
      ? "light-success"
      : "light-warning";
  }
};
export const paymentStatusDict = (appointment) => {
  const stringNames = {
    COMPLETED_AUTOMATICALLY: "Paid",
    COMPLETED_MANUALLY: "Paid",
    PENDING: "Pending",
  };
  if (
    appointment.appointmentStatus === "CANCELED" &&
    appointment.paymentStatus === "PENDING"
  ) {
    return "Not required";
  } else {
    return stringNames[appointment.paymentStatus];
  }
};

export const appointmentStatusDict = {
  SCHEDULED: "Scheduled",
  COMPLETED: "Completed",
  CANCELED: "Canceled",
  "CANCELED-PAID": "Canceled paid",
  DELETED: "Deleted",
};

export const appointementStatusColor = {
  COMPLETED: "light-success",
  SCHEDULED: "light-primary",
  CANCELED: "light-warning",
  "CANCELED-PAID": "light-danger",
  DELETED: "light-danger",
};

export const clearDataFromMeta = (
  obj,
  keys = [
    "createdBy",
    "updatedBy",
    "createdAt",
    "updatedAt",
    "deletedAt",
    "deletedBy",
  ]
) => {
  if (Array.isArray(obj)) {
    obj.forEach(function (item) {
      clearDataFromMeta(item, keys);
    });
  } else if (typeof obj === "object" && obj !== null) {
    Object.getOwnPropertyNames(obj).forEach(function (key) {
      if (keys.indexOf(key) !== -1) delete obj[key];
      else clearDataFromMeta(obj[key], keys);
    });
  }
};

export const formatDateMmomentConversion = (date) => {
  // Ensure the input is a Date object
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error("Invalid date provided");
  }

  // Get year, month, and day
  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Month is 0-indexed
  const day = date.getDate();

  // Get hours and minutes
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Pad the month, day, hours, and minutes with leading zeros if necessary
  const paddedMonth = month < 10 ? `0${month}` : month;
  const paddedDay = day < 10 ? `0${day}` : day;
  const paddedHours = hours < 10 ? `0${hours}` : hours;
  const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Format the date string
  return `${year}-${paddedMonth}-${paddedDay} ${paddedHours}:${paddedMinutes}`;
};

export const convertTimeToDifferentTimezone = (originalDate) => {
  return originalDate;
  // if (!originalDate) {
  //   return originalDate
  // }
  // console.log(originalDate, formatDateMmomentConversion(originalDate))
  // console.log(moment(formatDateMmomentConversion(originalDate)).toDate())
  //   // Create a moment object from the original date
  //   return moment(formatDateMmomentConversion(originalDate)).toDate()
};

export const parseDateString = (dateString) => {
  // Check if the dateString is correctly formatted
  if (!/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/.test(dateString)) {
    throw new Error("Invalid date format. Please use YYYY-MM-DD HH:mm.");
  }

  // Extract parts of the date from the string
  const parts = dateString.split(" ");
  const dateParts = parts[0].split("-");
  const timeParts = parts[1].split(":");

  // Extract year, month, day, hour, and minute from the parts
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10) - 1; // Month is 0-indexed in JavaScript
  const day = parseInt(dateParts[2], 10);
  const hour = parseInt(timeParts[0], 10);
  const minute = parseInt(timeParts[1], 10);

  // Create and return new Date object
  return new Date(year, month, day, hour, minute);
};

export const convertServerToLocale = (originalDate) => {
  // if (!originalDate) {
  return originalDate;
  // }
  // Create a moment object from the original date
  // return parseDateString(moment(originalDate).format('YYYY-MM-DD HH:mm'))
};

export const dayOfWeeksOptions = [
  { value: "1", label: "Poniedziałek" },
  { value: "2", label: "Wtorek" },
  { value: "3", label: "Środa" },
  { value: "4", label: "Czwartek" },
  { value: "5", label: "Piątek" },
  { value: "6", label: "Sobota" },
  { value: "7", label: "Niedziela" },
];

export const getCollectionName = (collection, store, isAdmin) => {
  const anonymize = window.location.href.indexOf("osrodek") > -1;
  if (anonymize) {
    if (isAdmin) {
      return collection;
    } else {
      return `${collection}Anonymized`;
    }
  } else {
    return collection;
  }
};

export const userStatusMap = {
  ACTIVE: { label: "Aktywny", value: "ACTIVE" },
  TERMINATION: { label: "Wygaszający", value: "TERMINATION" },
  ARCHIVED: { label: "Zarchiwizowany", value: "ARCHIVED" },
  PAUSED: { label: "Zapauzowany", value: "PAUSED" },
};

export const APPOINTMENT_TYPES = [
  { key: "RESERVATION", value: "Reservation" },
  { key: "FIRST_APPOINTMENT", value: "First appointment - new customer" },
  { key: "NEW_THERAPHIST", value: "First appointment - new therapist" },
];

export const appointmentRepeatOptions = (t) => [
  { label: t ? t("Weekly") : "Weekly", value: "Tygodniowe", moment: "w" },
  {
    label: t ? t("BiWeekly") : "BiWeekly",
    value: "Dwutygodniowe",
    moment: "w",
  },
  { label: t ? t("Monthly") : "Monthly", value: "Miesięczne", moment: "M" },
];

export const userStatusColorMap = {
  ACTIVE: "light-success",
  TERMINATION: "light-warning",
  ARCHIVED: "light-danger",
  PAUSED: "light-secondary",
};

export const appointementStatusColorTable = {
  SCHEDULED: "#3788d8",
  COMPLETED: "#28c76f",
  CANCELED: "#ff9f43",
  "CANCELED-PAID": "#ea5455",
};

export const selectStyles = {
  control: (baseStyles) => ({
    ...baseStyles,

    textAlign: "left",
  }),
  option: (base) => ({
    ...base,
    textAlign: "left",
  }),
};

export const appointmentStatusBadgeColorMap = {
  COMPLETED: "light-success",
  SCHEDULED: "light-primary",
  CANCELED: "light-warning",
  "CANCELED-PAID": "light-danger",
  DELETED: "light-danger",
};

export const formatCurrency = (value) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "PLN",
  }).format(value);

export const formatPercent = (value) =>
  new Intl.NumberFormat("de-DE", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
export const paymentTypes = (t) => [
  { label: t ? t("Online") : "Online", value: "Przelewy24" },
  { label: t ? t("Cash") : "Cash", value: "CASH" },
  { label: t ? t("Credit card") : "Credit card", value: "CARD" },
  { label: t ? t("Bank transfer") : "Bank transfer", value: "BANK_TRANSFER" },
];

export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const loadFromLocalStorage = (key, defaultValue) => {
  const savedValue = localStorage.getItem(key);
  return savedValue ? JSON.parse(savedValue) : defaultValue;
};

export const LOCAL_STORAGE_KEYS = {
  selectedDates: "payments_selectedDates",
  selectedUserId: "payments_selectedUserId",
  selectedCustomerId: "payments_selectedCustomerId",
  filtersVisible: "payments_filtersVisible",
};

export const contractTypes = [
  { label: "B2B", value: "B2B" },
  { label: "Umowa o pracę", value: "UoP" },
  { label: "Umowa o dzieło", value: "UoD" },
  { label: "Umowa o staż", value: "UoS" },
  { label: "Umowa o zlecenie", value: "UoZ" },
];

export const initialAppointmentStatusOptions = (t) => [
  { value: "SCHEDULED", label: t ? t("Scheduled") : "Scheduled" },
  { value: "COMPLETED", label: t ? t("Completed") : "Completed" },
  { value: "CANCELED-PAID", label: t ? t("Cancelled paid") : "Cancelled paid" },
  { value: "CANCELED", label: t ? t("Cancelled") : "Cancelled" },
];

export const initialCancelReasonOptions = [
  { label: "Odwołanie klienta", value: "CUSTOMER" },
  { label: "Przełożenie wizyty na inny termin", value: "APPOINTMENT_MOVED" },
  { label: "Choroba terapeuty", value: "THERAPIST_ILLNESS" },
  { label: "Urlop terapeuty", value: "THERAPIST_VACATION" },
  { label: "Zakończenie procesu", value: "PROCESS_TERMINATION" },
  { label: "Klient nie pojawił się na wizycie", value: "CUSTOMER_ABSENT" },
];

export const initialDateChangeReasonOptions = [
  { label: "Przełożenie klienta", value: "CUSTOMER" },
  { label: "Choroba terapeuty", value: "THERAPIST_ILLNESS" },
  { label: "Urlop terapeuty", value: "THERAPIST_VACATION" },
  { label: "Przełożona przez terapeutę", value: "THERAPIST_CHANGE" },
];

export const isMobileView = () => {
  return window.screen.width < 800;
};

export const getNestedProperty = (obj, path) =>
  path.split(".").reduce((acc, part) => acc && acc[part], obj);

const startDate = "2023-06-01";

export const getMonthList = (startDate, includeAdditional) => {
  const start = moment(startDate).startOf("month");
  const end = moment().startOf("month");
  const monthList = [];
  if (includeAdditional) {
    monthList.push({
      value: "*",
      label: "Pokaż wszystkie",
    });
    monthList.push({
      value: "empty",
      label: "Nierozliczone",
    });
  }
  while (end.isSameOrAfter(start)) {
    monthList.push({
      value: end.format("YYYY-MM"),
      label: end.format("YYYY-MM"),
    });
    end.subtract(1, "month");
  }
  return monthList;
};

export const monthsBetween = getMonthList(startDate);
export const monthsBetweenWithAdditional = getMonthList(startDate, true);

export const validatePolishIDNumber = (pesel) => {
  const weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
  let sum = 0;
  const controlNumber = parseInt((pesel || "").substring(10, 11));

  for (let i = 0; i < weight.length; i++) {
    sum += parseInt((pesel || "").substring(i, i + 1)) * weight[i];
  }
  sum = sum % 10;
  return (10 - sum) % 10 === controlNumber;
};

export const validatePolishCompanyNumber = (nip) => {
  if (typeof nip !== "string") return false;
  // eslint-disable-next-line
  nip = nip.replace(/[\ \-]/gi, "");

  const weight = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  let sum = 0;
  const controlNumber = parseInt(nip.substring(9, 10));
  const weightCount = weight.length;
  for (let i = 0; i < weightCount; i++) {
    sum += parseInt(nip.substr(i, 1)) * weight[i];
  }

  return sum % 11 === controlNumber;
};

export const mapCustomerCSV = (firebaseData, t) => {
  const customerData = firebaseData;

  // Mapa statusu GDPR
  const gdprStatusDict = {
    PENDING: t("Oczekujący"),
    ACCEPTED: t("Zaakceptowany"),
    REJECTED: t("Odrzucony"),
  };

  return {
    "ID Klienta": customerData.id,
    Imię: customerData.firstName,
    Nazwisko: customerData.lastName,
    Email: customerData.email,
    Skype: customerData.skype,
    "Numer telefonu": customerData.phoneNumber,
    "Status GDPR": gdprStatusDict[customerData.gdprStatus || "PENDING"], // Domyślnie "Oczekujący"
    "Data podpisania umowy": customerData.contractSignDate
      ? moment(customerData.contractSignDate.toDate()).format(
          "YYYY-MM-DD HH:mm"
        )
      : "",
    "Liczba odwołanych wizyt": (customerData.cancelledAppointments || [])
      .length,
    "Daty odwołanych wizyt": (customerData.cancelledAppointments || [])
      .map((c) => moment(c.date.toDate()).format("YYYY-MM-DD HH:mm"))
      .join(", "),
    "Przypisani użytkownicy": (customerData.assignedUsers || [])
      .map((user) => `${user.firstName} ${user.lastName}`)
      .join(", "),
    "Nazwa źródła": customerData.source?.name || "",
    Komentarz: customerData.comment,
    Newsletter: customerData.newsletter ? "Tak" : "Nie",
  };
};
